import * as React from "react"
import { Link } from "gatsby"
import * as footerStyles from "./footer.module.css"

const Footer = () => (
  <div className="section last">
    <div className="contentWrap footer">
      <div className={footerStyles.footer}>
        <text className={footerStyles.a}>TEL: 015908167673</text>
        <text className={footerStyles.b}>E-MAIL: NIKOLAI@ALTERGOTT.STUDIO</text>
        <Link
          to="/datenschutz"
          className={`${footerStyles.link} ${footerStyles.e}`}
        >
          Datenschutz
        </Link>
        <Link
          to="/impressum"
          className={`${footerStyles.link} ${footerStyles.f}`}
        >
          Impressum
        </Link>
        <text className={footerStyles.c}>
          Altergott Studios<br></br>Lange Äcker 1, 71543 Wüstenrot<br></br>
          Deutschland
        </text>
        <Link
          to="/kontakt"
          className={`${footerStyles.link} ${footerStyles.d}`}
        >
          Kontakt
        </Link>
      </div>
    </div>
  </div>
)

export default Footer
