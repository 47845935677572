import * as React from "react"
import { Button } from "react-bootstrap"
import * as headerStyles from "./header.module.css"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <div className={headerStyles.headerWrapper}>
      <Link to="/" className={headerStyles.buttonLink}>
        <Button className="actionButton black" variant="outline-dark">Zurück</Button>
      </Link>
      <Link to="/">
      <h3>Altergott Studios</h3>
      </Link>
    </div>
  </header>
)

export default Header
